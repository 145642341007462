import React from "react";

import SignedOutLayout from "../components/recycle-more/signedOut";

const Pricing = () => {
  return (
    <SignedOutLayout seoTitle="Pricing" />
  )
};

export default Pricing
